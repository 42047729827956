import {
  appointmentLayout,
} from "./ServiceFilterHtml";
import { builderData, APOData, templateJSON } from "./BuilderProps";
import { updateHandlebarsTemp, convertTimeToMinutes } from "./CommonMethod";
import { APO__DataUpdate } from "./OnboardingData";
import moment from "moment";
import { GetAPI_deleteAPI_method } from "./Api";
import "./SwitchServiceStep";
// import 'moment-timezone';
// import datepicker from 'js-datepicker'
// import 'js-datepicker/dist/datepicker.min.css';

(async function () {
  $(".S_customSelect").customSelect();

  // service layout filter
  $(".serviceLayoutFilter").change(async function () {
    templateJSON.StyleObj["Service"].layout = this.value; // set service type globally
    const { APOtype } = APOData;
    await appointmentLayout(APOData[APOtype]);
  });

})();

window.filterAppointment = async (event) => {
  const value = event.target.value;
  // Set service type and APO type globally
  templateJSON.StyleObj["Service"].APOtype = value;
  APOData.APOtype = value;

  try {
    await appointmentLayout();
  } catch (error) {
    console.error("Error in filterAppointment:", error.message);
  }
};

export const get__ServiceEventsClasses = async () => {
  try {
    const { APOtype, onboardingData } = APOData;
    let data = [];

    // Fetch data if the type is not "Service" and the data is not already loaded
    switch (APOtype) {
      case "Service":
        data = onboardingData.serviceMenusList;
        break;
      default:
        const response = await GetAPI_deleteAPI_method(APOtype, "GET");
        data = response.data;
    }

    // Update and return the data for the given APOtype
    APOData[APOtype] = await APO__DataUpdate(data, APOtype);
    return APOData[APOtype];

  } catch (error) {
    console.error("Error fetching data:", error.message);
    return [];
  }
};

export const initdatepicker = () => {
  const { onboardingData, APOtype } = APOData;
  const { businessHoursList } = onboardingData;
  const selectedAPO = APOData[APOtype][0]; // selected service default first

  let selectedDay = moment(new Date());
  const { APO_selectedData } = APOData;
  APO_selectedData.date = selectedDay;

  // Disable dates
  const disabledDays = businessHoursList
    .map((hours, index) => (!hours.isOpened ? (index + 1) % 7 : null))
    .filter((day) => day !== null)
    .join(",");

  $("#datepicker")
    .datepicker({
      daysOfWeekDisabled: disabledDays,
      format: "mm/dd/yyyy",
      startDate: new Date(),
      weekStart: 1,
      todayHighlight: true,
      templates: {
        leftArrow: '<i class="fa fa-angle-left"></i>',
        rightArrow: '<i class="fa fa-angle-right"></i>',
      },
      beforeShowDay: function (date) {
        //return {
        //  content: `<span>${new Date(date).getDate()}</span>`
        //}
      },
    })
    .on("changeDate", function (e) {
      // const SDay = e.format("DD");
      const selectedDay = moment(e.date);
      APO_selectedData.date = selectedDay;
      document
        .querySelectorAll(".datepicker-days .day")
        .forEach((day) => day.classList.remove("today")); // Remove 'today' class on date change
      serviceDuration(selectedDay, selectedAPO.duration, businessHoursList); // check serive duration
    });
  $("#datepicker").datepicker("setDate", selectedDay.format("l"));
  //serviceDuration(selectedDay, selectedAPO.duration, businessHoursList); // check serive duration
  // console.log(JSON.stringify(businessHoursList))
};

// Function to check service duration
const serviceDuration = (selectedDay, duration, hoursList) => {
  //.format("dddd")
  const { APOtype } = APOData;
  const dayName = selectedDay.format("dddd");
  const businessHours = hoursList.find((item) => item.day === dayName);
  if (!businessHours || !businessHours.isOpened) {
    console.error(
      `No business hours found for ${dayName} or the business is closed on ${dayName}.`
    );
    return;
  }
  const { from, to, breakHours } = businessHours;
  const startTime = moment(from).format("HH:mm");
  const endTime = moment(to).format("HH:mm");
  const totalMinutes = convertTimeToMinutes(duration, APOtype); // convert time to minutes
  const timeData = { startTime, endTime, duration: totalMinutes, breakHours };
  generateServiceTimeSlots(timeData, selectedDay); // calculate service time slots
};

// Function to calculate service time slots
const generateServiceTimeSlots = async (timeData, selectedDay) => {
  const { startTime, endTime, duration, breakHours } = timeData;

  const isToday = moment().format("DD") === selectedDay.format("DD");
  const isTodayDate = moment(new Date()).format("l") <= selectedDay.format("l");
  const currentTime = moment().format("HH:mm");
  const isStartTimePassed = currentTime >= startTime;
  const availableServiceSlots = [];
  let timeSlot = moment(startTime, "HH:mm");

  // while (timeSlot.add(duration, 'minutes').isBefore(moment(endTime, "HH:mm"))) {
  while (timeSlot.isBefore(moment(endTime, "HH:mm"))) {
    const slotTime = timeSlot.format("HH:mm");
    if (!isToday || !isTodayDate || currentTime < slotTime) {
      const fullTimestamp = moment(selectedDay).set({
        hour: timeSlot.hour(),
        minute: timeSlot.minute(),
      });

      availableServiceSlots.push({
        slot: slotTime,
        timestamp: fullTimestamp.unix(),
        break: isWithinBreakTime(slotTime, breakHours),
      });
    }
    // Increment the timeSlot by duration
    timeSlot = timeSlot.clone().add(duration, "minutes");
  }

  // Select the DOM element where the appointment slots will be rendered
  const SlotEle = document.querySelector(".Service .AppointmentSlot");

  // Get the Handlebars template from the script element
  const template = document.getElementById(`template_AvailableSlot`).innerHTML;
  // Compile and update the Handlebars template with the prepared data
  await updateHandlebarsTemp(SlotEle, template, {Bookingslots: availableServiceSlots});
};

// Function to check if the time is within break hours
const isWithinBreakTime = (time, breakHours) => {
  if (!breakHours || breakHours.length === 0) return false;
  return breakHours.some((breakTime) => {
    const breakStartTime = moment(breakTime.from).format("HH:mm");
    const breakEndTime = moment(breakTime.to).format("HH:mm");
    return time >= breakStartTime && time < breakEndTime;
  });
};
