import { managePropSlide } from ".";
import { builderData } from "./BuilderProps";
import { destroyALLCKinstances } from "./CommonMethod";
const previewBoxHtml = () => {
  return `<div class="template-preview">
            <div class="template-preview__title-bar flex flex-nowrap items-center justify-between">
                <div class="template-preview__col template-preview--left">
                    <span class="template-preview_title pr-4">Preview mode</span>
                </div>
                <div class="template-preview__column template-preview--previewChoice flex items-center mx-1 gap-9">
                    <div class="previewChoice_viewport flex gap-2">
                        <button type="button" onclick="switchViewport(event)" data-viewport="fullscreen" class="viewportButton"><i class="fa-solid fa-expand"></i></button>
                        <button type="button" onclick="switchViewport(event)" data-viewport="desktop" class="viewportButton selected"><i class="fa-solid fa-display"></i></button>
                        <button type="button" onclick="switchViewport(event)" data-viewport="tablet" class="viewportButton"><i class="fa-solid fa-tablet-screen-button"></i></button>
                        <button type="button" onclick="switchViewport(event)" data-viewport="phone" class="viewportButton"><i class="fa-solid fa-mobile-screen"></i></button>
                    </div>
                    <div class="PreviewChoice_viewportSize flex items-center gap-4" style="width: 160px;">
                        <input type="text" class="input-field viewport-inputWidth" value="1720" disabled style="height: 25px; border-color: var(--gray-200);">
                        <span> ✕ </span>
                        <input type="text" class="input-field viewport-inputHeight" value="709" disabled style="height: 25px; border-color: var(--gray-200);">
                    </div>
                </div>
                <div class="template-preview__col template-preview--right flex items-center justify-center" onclick="closePreview(event, this)">
                    <i class="fa-solid fa-xmark"></i>
                </div>
            </div>
            <div class="template-preview__viewport">
                <div class="viewport--content" data-viewport="desktop" style="width: 1728px;height: 717px;max-height: 749px;">
                    <iframe id="previewFrame" width="100%" height="100%" frameborder="0"></iframe>
                </div>
            </div>
        </div>`;
};

const previewIframeContent = () => {
  const insertData = document.getElementById("insertData");

  managePropSlide();

  // remove CK editor instances and ID
  //   destroyALLCKinstances(insertData);

  return `<!DOCTYPE html>
    <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title></title>
            <link rel="preconnect" href="https://fonts.googleapis.com">
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
            <link rel="stylesheet" href="/dist/styles.css">
            <style>
                .svg-inline--fa {
                    height: 1em;
                    overflow: visible;
                    vertical-align: -.125em;
                }
            </style>
            <script>
                document.addEventListener("DOMContentLoaded", function() {
                    const anchorLinks = document.querySelectorAll("a");
                    anchorLinks.forEach((link) => {
                    link.addEventListener("click", (event) => {
                    event.preventDefault();
                        });
                    });
                });
            </script>
        </head>
        <body>
            ${insertData.innerHTML}
        </body>
    </html>`;
};

window.TemplatePreview = (event) => {
  const EditorWrapper = document.querySelector(".EditorMain-wrapper");

  if (!EditorWrapper.classList.contains("previewActive")) {
    EditorWrapper.classList.add("previewActive");

    const previewHtml = previewBoxHtml();
    EditorWrapper.insertAdjacentHTML("afterbegin", previewHtml);

    // Once the iframe is created, write content to it
    const iframe = document.getElementById("previewFrame");

    // Build the full HTML with meta tags, CSS, and JS
    const iframeContent = previewIframeContent();

    // Set the iframe srcdoc attribute to the prepared content
    iframe.srcdoc = iframeContent;

    updateViewPort();
  }
};

window.closePreview = (event) => {
  const EditorWrapper = document.querySelector(".EditorMain-wrapper");
  EditorWrapper.classList.remove("previewActive");
  EditorWrapper.querySelector(".template-preview").remove();
  builderData.previewViewport = "desktop";
};

window.switchViewport = (event) => {
  const clickedButton = event.currentTarget;
  builderData.previewViewport = clickedButton.dataset.viewport;

  // Remove 'selected' class from all buttons and add to the clicked one
  const buttons = document.querySelectorAll(".viewportButton");
  buttons.forEach((button) => button.classList.remove("selected"));
  clickedButton.classList.add("selected");

  updateViewPort();
};

const updateViewPort = () => {
  const EditorWrapper = document.querySelector(".EditorMain-wrapper");
  if (EditorWrapper.classList.contains("previewActive")) {
    const viewportContent = EditorWrapper.querySelector(".viewport--content");
    viewportContent.dataset.viewport = builderData.previewViewport;

    // Get viewport dimensions
    const inputWidth = document.querySelector(".viewport-inputWidth");
    const inputHeight = document.querySelector(".viewport-inputHeight");

    // Disable/enable inputs based on viewport mode
    //   const isFullscreen = builderData.previewViewport === "fullscreen";
    //   inputWidth.disabled = isFullscreen;
    //   inputHeight.disabled = isFullscreen;

    // Get window dimensions
    const { innerWidth: w_innerWidth, innerHeight: w_innerHeight } = window;

    // Set viewport dimensions
    const viewportSizes = {
      fullscreen: {
        width: w_innerWidth,
        height: w_innerHeight,
        maxHeight: 801,
      },
      desktop: {
        width: w_innerWidth - 200,
        height: w_innerHeight - 200,
        maxHeight: 749,
      },
      tablet: {
        width: w_innerWidth - 896,
        height: w_innerHeight - 200,
        maxHeight: 749,
      },
      phone: {
        width: w_innerWidth - 1600,
        height: w_innerHeight - 200,
        maxHeight: 749,
      },
    };

    const { width, height, maxHeight } =
      viewportSizes[builderData.previewViewport];
    inputWidth.value = width;
    inputHeight.value = height;

    // Apply dimensions to the viewport content
    viewportContent.style.width = `${width}px`;
    viewportContent.style.height = `${height}px`;
    viewportContent.style.maxHeight = `${maxHeight}px`;
  }
};

window.onresize = function (event) {
  updateViewPort();
};
