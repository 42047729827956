import { GetAPI_deleteAPI_method } from "./Api";
import { builderData, APOData, templateJSON } from "./BuilderProps";
import {
  findSuperParentWithClass,
  updateHandlebarsTemp,
  mouserOver_method,
} from "./CommonMethod";
import { onFlyProps_remove } from "./PropertySetting";
import { initdatepicker } from "./Service";
import { appointmentLayout } from "./ServiceFilterHtml";
import { ServiceStyle } from "./ShowModuleStyle";
import moment from "moment";


(async function () {
  // Service filter change handler
  $(".serviceStep").change(async function () {
    const { APOtype } = APOData;
    const WName = "Service";
    const templateName = this.options[this.selectedIndex].dataset.template;
    APOData.APO_selectedData.serviceActiveStep = templateName;

    const parentElement = findSuperParentWithClass(
      builderData.editWidget,
      WName
    );

    // Toggle visibility based on selected step
    document.querySelector(".ServiceFilter").style.display =
      this.value !== "1" ? "none" : "";
    try {
      switch (templateName) {
        case "Servicelist":
          $("#datepicker").datepicker("destroy");
          builderData.widgetName = WName;
          ServiceStyle(WName);
          appointmentLayout(APOData[APOtype]);
          break;
        case "stafflist":
        case "Datetime":
        case "StafflistDatetime":
          onFlyProps_remove();
          await StafflistDatetime(templateName, WName);
          break;
        case "UserDetails":
          if (
            templateName === "UserDetails" ||
            (templateName === "Datetime" && APOtype === "Events")
          ) {
            onFlyProps_remove();
            await APO__orderdone(templateName, WName);
          }
          break;
        default:
          console.warn(`Unknown template name: ${templateName}`);
      }
    } catch (error) {
      console.error("Error handling service step change:", error);
    }
  });
})();

const StafflistDatetime = async (templateName, WName) => {
  try {
    // Ensure selected appointment data is available
    await previewSelectedAPO_data();

    // Destructure necessary data from APOData
    const { onboardingData, APO_selectedData } = APOData;

    // Select the element where the template will be appended
    const appendEle = document.querySelector(`.${WName} .APO_booking_content`);
    const template = document.getElementById(`template_${templateName}`).innerHTML;

    // Prepare data for the Handlebars template
    const templateData = {
      appointmentData: [APO_selectedData.appointmentJSON],
      stafflist: onboardingData.staffMembersList,
      isSkipTeamMembers: false
      // staffRule: false
    };

    // Compile the Handlebars template and update the UI
    await updateHandlebarsTemp(appendEle, template, templateData);

    // Show class on row and widget hover
    mouserOver_method(); 

    // Initialize datepicker if the template requires it
    if (["Datetime", "StafflistDatetime"].includes(templateName)) {
      initdatepicker();
    }
  } catch (error) {
    console.error("Error in processing staff list and datetime template:", error);
  }
};

const previewSelectedAPO_data = async () => {
  const { onboardingData, APOtype, APO_selectedData } = APOData;
  const serviceID = APOData[APOtype][0].id;
  const appointmentData = _.find(APOData[APOtype], { id: serviceID });
  APO_selectedData.appointmentJSON = appointmentData;

  const staffID = onboardingData.staffMembersList[0].id;
  const staffJSON = _.find(onboardingData.staffMembersList, { id: staffID });
  APO_selectedData.staffJSON = staffJSON;

  const openedDay = APO_previewDate(onboardingData.businessHoursList);
  APO_selectedData.timeslot = openedDay.unix();
};

const APO_previewDate = (businessHours) => {
  const selectedDay = moment();
  const today = selectedDay.format("dddd");
  const todayIndex = businessHours.findIndex((day) => day.day === today);
  const dayMap = {
    Monday: 0,
    Tuesday: 1,
    Wednesday: 2,
    Thursday: 3,
    Friday: 4,
    Saturday: 5,
    Sunday: 6,
  };

  for (let i = 0; i < businessHours.length; i++) {
    const dayIndex = (todayIndex + i) % businessHours.length;
    const dayData = businessHours[dayIndex];

    if (dayData.isOpened) {
      const daysToAdd = (dayMap[dayData.day] - todayIndex + 7) % 7;
      return selectedDay.clone().add(daysToAdd, "days");
    }
  }

  return null; // No open days found
};

const APO__orderdone = async (templateName, WName) => {
  try {
    await previewSelectedAPO_data();
    const { APO_selectedData, CountriesList, onboardingData } = APOData;
    const { Signup } = templateJSON.StyleObj;

    if (!CountriesList.length) {
      const response = await GetAPI_deleteAPI_method("Countries", "GET");
      Object.assign(CountriesList, response.data);
    }

    const appendEle = document.querySelector(`.${WName} .APO_booking_content`);
    const template = document.getElementById(`template_${templateName}`).innerHTML;

    const orderDetail = {
      ...APO_selectedData,
      CountriesList,
      form: Signup.form,
      UserCountries: onboardingData.userCountry
    };

    await updateHandlebarsTemp(appendEle, template, orderDetail); // Compile Handlebars template

    $(".DropwithSearch").customSelect({ search: true }); // Initialize custom select
    mouserOver_method(); // Show class on row and widget hover
  } catch (error) {
    console.error("Error in Customer Detail:", error);
  }
};
