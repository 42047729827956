import { APOData, templateJSON } from "./BuilderProps";
import { updateHandlebarsTemp } from "./CommonMethod";
import { get__ServiceEventClass } from "./Service";

(async function () {
  // $(".S_customSelect").customSelect();

  // service layout filter
  $(".serviceLayoutFilter").change(async function () {
    templateJSON.StyleObj["Service"].layout = this.value; // set service type globally
    const { APOtype } = APOData;
    await appointmentLayout(APOData[APOtype]);
  });

})();

window.filterAppointment = async (event) => {
  const value = event.target.value;
  // Set service type and APO type globally
  templateJSON.StyleObj["Service"].APOtype = value;
  APOData.APOtype = value;

  try {
    await appointmentLayout();
  } catch (error) {
    console.error("Error in filterAppointment:", error.message);
  }
};

export const appointmentLayout = async (result) => {
  // Fetch appointment data based on the selected value
  const data = result ? result :  await get__ServiceEventClass();
  if (data.length) {
    const { appointmentFilter } = APOData;

    const appendElement = document.querySelector(`.Service .APO_booking_content`);
    const template = document.getElementById(`template_service${templateJSON.StyleObj["Service"].layout}`).innerHTML;

    await updateHandlebarsTemp(appendElement, template, {
      appointmentData: data,
      appointmentFilter,
    }); // Compile Handlebars template method

    $(".S_customSelect").customSelect();
  }

  // display team list
  displayTeamList();
};

const displayTeamList = async () => {
  const { onboardingData } = APOData;

  const appendElement = document.querySelector(`.TeamList .team-wrapper`);
  const template = document.getElementById(`template_teamList`).innerHTML;

  await updateHandlebarsTemp(appendElement, template, {
    teamList: onboardingData.staffMembersList,
  }); // Compile Handlebars template method

}
