import { APOData, builderData } from "./BuilderProps";
import { GetAPI_deleteAPI_method } from "./Api";
import { appointmentLayout } from "./ServiceFilterHtml";
import { templateSetting } from "./TemplateSettings";
// import { bookedAppointmentsList } from "./BookedAppointmentsList";

export const OnboardingData = async () => {
  try {
    const response = await GetAPI_deleteAPI_method("Onboarding", "GET");
    const { data } = response;
    APOData.onboardingData = data; // Service list

    // Update appointment Filter
    const filters = [
      { condition: data.isServiceAdded, filter: "Service" },
      { condition: data.isEventAdded, filter: "Event" },
      { condition: data.isClassAdded, filter: "Class" },
    ];
    APOData.appointmentFilter = filters.filter((item) => item.condition).map((item) => item.filter);

    APOData.APOtype = APOData.appointmentFilter[0];

    await templateSetting(); // Template settings

    // Layout and template settings
    await appointmentLayout();
  } catch (error) {
    console.error("Error during onboarding data processing:", error);
  }
};

export const APO__DataUpdate = async (data, type) => {
  try {
    const { userCurrencySymbol } = APOData.onboardingData;
    return data.map((item) => ({
      ...item,
      currencySymbol: userCurrencySymbol,
      APOtype: type,
    }));
  } catch (error) {
    console.error("Error updating APO data:", error);
    throw error; // Re-throw the error if necessary
  }
};
