import { APOData, templateJSON } from "./BuilderProps";
import { updateHandlebarsTemp } from "./CommonMethod";
import { get__ServiceEventsClasses } from "./Service";

export const appointmentLayout = async (result) => {
  // Fetch appointment data based on the selected value
  const data = result ? result :  await get__ServiceEventsClasses();
  if (data.length) {
    const { appointmentFilter } = APOData;

    const appendElement = document.querySelector(`.Service .APO_booking_content`);

    const template = document.getElementById(
      `template_service${templateJSON.StyleObj["Service"].layout}`
    ).innerHTML;
    await updateHandlebarsTemp(appendElement, template, {
      appointmentData: data,
      appointmentFilter,
    }); // Compile Handlebars template method

    $(".S_customSelect").customSelect();
  }
};
