import { Sortable, Plugins } from "@shopify/draggable";

// const Classes = {
//   draggable: "widget-draggable",
// };
export const initDragDropMethod = () => {
  Row_DragDropMethod();
  Column_DragDropMethod();
}
export const Row_DragDropMethod = () => {
  let rowDroptarget = document.querySelectorAll(".row-droptarget");
  let scrollAble = document.querySelectorAll(".Editor__component");
  if (rowDroptarget.length === 0) {
    return false;
  }

  const sortable = new Sortable(rowDroptarget, {
    draggable: ".row-draggable",
    handle: ".row_handle",
    swapAnimation: {
      duration: 200,
      easingFunction: "ease-in-out",
    },
    mirror: {
      cursorOffsetX: 0,
      cursorOffsetY: 0,
      constrainDimensions: true,
    },
    scrollable: {
        speed: 6,
        sensitivity: 12,
        scrollableElements: [
          ...scrollAble,
        ],
      },
    plugins: [Plugins.ResizeMirror],
  });

  // --- Draggable events --- //
  sortable.on("mirror:attached", (evt) => {
    evt.mirror.className = "";
    evt.mirror.innerHTML = `<div class="mirror-preview rowMirror" style="">
            <img alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAATEAAABaCAMAAADn/5F3AAABRFBMVEXY2Njb29vZ3N/Z3N3Y297Y297Y2t7a3ODa3eDn6evl5+nm5+nm6Orn6ern6evn6ezo6uyYmJiampqbm5ucnJydnZ2fn5+goKChoaGioqKkpKSlpaWmpqanp6eoqKiqqqqrq6usrKyurq6wsLCxsbGysrKzs7O0tLS1tbW2tra3t7e4uLi5ubm6urq7u7u8vLy9vb2+vr6/v7/AwMDBwcHCwsLDw8PExMTFxcXGxsbHx8fIyMjJycnKysrLy8vMzMzOzs7Pz8/Q0NDR0dHS0tLT09PU1NTW1tbX19fZ2dna2trb29vc3Nzd3d3e3t7f39/g4ODh4eHi4uLj4+Pk5OTl5eXm5ubn5+fo6Ojp6enp6+zq6urq7O3r6+vs7Ozs7e/t7e3u7u7v7+/x8fHy8vL09PT29vb39/f5+fn9/f3+/v7///891x2jAAAAEXRSTlMNDq2usMDD8/P2+Pj4+Pn5+lvcqiIAAAABYktHRGtSZaWYAAACfklEQVR42u2d3VMSYRyFF7NcrbQsTUzD0FLCBFe+sdSI1CRs1RUs6VMr8f+/74KBGd+zw7hjXpjPmd/Fzpn3vMs8u2feu8UK9dr9X9H51G/fClm9A81TdF41b/dYNsACIeuzhqAQSHetQyAE0iHEIAYxiEEMYigYsT/fv6Az+nnSndg3EJk67kqsCSDREcQgBjGIQQxiEIMYxCAGMYihCxLznqn38YV6lQX1Nhz1yhn11orqvVpRL/9GvdQ79ZKbcoeCYcTcSyPmjvnQmVBv/al6JR/ar+M+JJLqLaXVW/AhO7eq3rRQzCcMY+LDpRH7XFHvwMer+fyE/W2fd9bn4e76eDu7Pk9vz+d999Sr7pvOnnmHygGtpJW0klbSSlpJK2klrfy/WlnM1ltTSNQ71+3xnKyYqfeyrJ5LatZNa9apajbtaLaa0ezijmaXUqaTWTSczbxula1fhFjGqbXGGa91rtuz9cQRMz4vy2rxiGbXopqdyWl2Zlqz2TnNTpU0OxUzndmo4cwmdSunRis5KzkrOStpJa2klbSSVtJKWkkraeW/Iua6jdZsRRud6/ZsP3fFLC/IssbqS82+TWq2UNRsJqfZ/LJmE2XNxkumU0wZTmxDt3IbFyEWCXutmbzvda7bsz4cFnM0Isu88WHNFkY1OzKv2UcPNRsPa/bBsmZHRk1nzNzt8aRuFfZoJWclZyVnJa2klbSSVtJKWkkraSWtpJW08sq18noLYhCDGMQgBjGIQQxiEIPYdSZ2+gNCpn51J3ZyfITO6DdfBeQ7ihCDGMQghiAGMYhdbWL3gBBIg5YNhCBq9lk37/BnNgGADfRYoV576BM6nwbtG9Zfk0s2FZSMvPwAAAAASUVORK5CYII=">
        </div>`;
  });
  sortable.on("sortable:start", (evt) => {
    //$('[builder-element], .row-container-outer').removeClass('element-hightlighted');
  });
  sortable.on("sortable:stop", (evt) => {
    if (evt.oldContainer.children.length == 1) {
      evt.oldContainer.innerHTML = `<div class="row-empty row-draggable">Drop content Block here</div>`;
    }
    if (!!evt.newContainer.querySelector(".row-empty")) {
      evt.newContainer.querySelector(".row-empty").remove();
    }
  });

  return sortable;
};
export const Column_DragDropMethod = () => {
  let ColumnDroptarget = document.querySelectorAll(".column-droptarget");
  if (ColumnDroptarget.length === 0) {
    return false;
  }

  const sortable = new Sortable(ColumnDroptarget, {
    draggable: ".widget-draggable",
    handle: ".column_handle",
    swapAnimation: {
      duration: 500,
      easingFunction: "ease-in-out",
    },
    mirror: {
      cursorOffsetX: 0,
      cursorOffsetY: 0,
      constrainDimensions: true,
    },
    plugins: [Plugins.ResizeMirror],
  });

  // --- Draggable events --- //
  sortable.on("mirror:attached", (evt) => {
    evt.mirror.className = "";
    evt.mirror.innerHTML = `<div class="mirror-preview widgetMirror" style="">
            <img alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGkAAABaCAMAAACMsogLAAAAvVBMVEXY2Njb29vZ3N/Z3N3Y297Y297Y2t7a3ODa3eDn6evl5+nm5+nm6Orn6ern6evn6ezo6uyXl5eYmJiZmZmampqkpKSoqKirq6utra2xsbGzs7O0tLS5ubnBwcHIyMjKysrPz8/Y2NjZ2dnb29ve3t7h4eHj4+Pk5OTl5eXm5ubn5+fo6Ojp6enp6+zq6urq7O3r6+vs7Ozs7e/t7e3u7u7v7+/19fX29vb39/f4+Pj5+fn8/Pz9/f3+/v7///+DLzoCAAAAEXRSTlMNDq2usMDD8/P2+Pj4+Pn5+lvcqiIAAAABYktHRD5JZADjAAABF0lEQVRo3u2aVwvCMBzEU2ectY669151b+v3/1i2gjURqghpEL3jHv6XPvzgSF6aECVAI0uvFaEhhQSi5sV7mTEfoTJAFipM1IscJchMEmkGEkivSLvNWpw2e3fSYSFWR1fSVjBpJ420BQkkkEASSjJeZkMcyUg/LWhcyjeFkebZp4UMlwpNtIf20N7/tTdpdxyPmdl2i4sj7uvkY1JXL99dSj/mmzUu6jk2drH3cHLRHtpDe29IvUrVcZaZbWtczBfZ2PuYNKw3HA+Y2XaNi33u6xB7DycX7aG9H2gP/1hAAgkkkL6NtHclnVZCQauT+z3h+ShSJu5zQfo3UkoSKUmoHJD1YiYYl/IKKOoj1ssmdeq1ktRPrqW2qCU3ehSjAAAAAElFTkSuQmCC">
        </div>`;
  });
  sortable.on("sortable:start", (evt) => {
    //$('[builder-element], .row-container-outer').removeClass('element-hightlighted');
  });
  sortable.on("sortable:stop", (evt) => {
    if (evt.oldContainer.children.length == 1) {
      evt.oldContainer.innerHTML = `<div class="module-empty widget-draggable">Drop content Block here</div>`;
    }
    if (!!evt.newContainer.querySelector(".module-empty")) {
      evt.newContainer.querySelector(".module-empty").remove();
    }
  });
  return sortable;
};
