import AWS from "aws-sdk";
import { v4 as uuidv4 } from "uuid";
import { builderData } from "./BuilderProps";
import { setBtnLoader } from "./CommonMethod";
import { applybackgroundImage } from "./PropertySetting";

AWS.config.update({
  region: "eu-west-1", // Your region
  credentials: {
    accessKeyId: process.env.AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY,
  },
});

const s3 = new AWS.S3({
  params: { Bucket: "appol-resources" }, // Your bucket name
});

window.uploadImageAWS = async (event) => {
  const applyOn = event.target.dataset.apply;
  const { editWidget } = builderData;

  const file = event.target.files[0];
  if (!file) {
    return;
  }
  const uploadBtnElement = event.currentTarget.previousElementSibling;

  setBtnLoader(true, uploadBtnElement, [21]);
  const id = uuidv4();
  const params = {
    Key: `Themes/${id}${file.name}`,
    Body: file,
    ACL: "public-read", // Adjust according to your requirements
    ContentType: file.type,
  };

  s3.upload(params, function (err, data) {
    if (err) {
      console.log("Error uploading image: ", err);
      // alert('There was an error uploading your image. Please try again.');
    } else {

      document.querySelector(".upImageURL").value = data.Location;

      applybackgroundImage(data.Location, applyOn);

      // console.log("Successfully uploaded image.", data);
    }
    setBtnLoader(false, uploadBtnElement);
  });
};
