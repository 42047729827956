import { APOData, builderData, templateJSON } from "./BuilderProps";
import { GetAPI_deleteAPI_method, ThemeType } from "./Api";
import { updateHandlebarsTemp } from "./CommonMethod";

export const templateSetting = async () => {
  try {
    await updateOpeningHours(); // Update opening hours
    await updateContactData(); // Update contact details
    await fetchBookingRules(); // Fetch and apply booking rules

    if (!parseInt(ThemeType)) {
      await defaultTemplateStyle(); // Apply default template style
    }
  } catch (error) {
    console.error("Error in updating template settings:", error);
  }
};

export const themeTypeSetting = async () => {
  const { isTabs, templatePageUnder } = templateJSON;

  if (templatePageUnder === "tabs") {
    const switchPage = document.querySelector(".switchPage");
    switchPage.remove();
  }

   // Initialize jQuery UI tabs with custom active class handling
  if (isTabs) {
    $(".TabWidget .widge-TabContant").tabs({
      active: 0, // Activate the first tab (index 0)
      activate: function (event, ui) {
        ui.oldTab.removeClass("active");
        ui.newTab.addClass("active");
      },
    });
  }
};

const updateOpeningHours = async () => {
  try {
    const openingH = document.querySelector(".Bhours-list");
    const template = document.getElementById("template_Bhours").innerHTML;
    await updateHandlebarsTemp(openingH, template, APOData.onboardingData); // Compile Handlebars template method
  } catch (error) {
    console.error("Error updating opening hours:", error);
  }
};

const updateContactData = async () => {
  try {
    const addressSection = document.querySelector(".Baddress-detail");
    const template = document.getElementById("template_Baddress").innerHTML;
    await updateHandlebarsTemp(
      addressSection,
      template,
      APOData.onboardingData
    ); // Compile Handlebars template method
  } catch (error) {
    console.error("Error updating contact data:", error);
  }
};

const fetchBookingRules = async () => {
  try {
    const response = await GetAPI_deleteAPI_method("BookingRules", "GET");
    APOData.Bookingrules = response.data;
  } catch (error) {
    console.error("Error fetching booking rules:", error);
  }
};

export const filterBookingRule = async (allowedKeys) => {
  const { bookingRulesLists } = APOData.Bookingrules;

  if (allowedKeys.length > 1) {
    return bookingRulesLists.filter((rule) =>
      allowedKeys.includes(rule.keyName)
    );
  } else {
    return bookingRulesLists.find((rule) => rule.keyName === allowedKeys[0]);
  }
};

const defaultTemplateStyle = async () => {
  try {
    const response = await GetAPI_deleteAPI_method("TemplateSettings", "GET");
    
    if (response.status === 200) {
        const { data } = response;
        Object.assign(builderData.templateSetting, data);
    }

    const { brandBannerUrl, brandLogoUrl } = APOData.onboardingData;
    
    const APOBanner = document.querySelector(".APO-banner");
    const APOLogo = document.querySelector(".APO-logo");
    if (brandBannerUrl && APOBanner) {
      APOBanner.style.backgroundImage = `url(${brandBannerUrl})`;
    }
    if (brandLogoUrl && APOLogo) {
      APOLogo.src = brandLogoUrl;
    }

  } catch (error) {
    console.error("Error fetching template style:", error);
  }
};
