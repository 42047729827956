import { v4 as uuidv4 } from "uuid";
// global variable
export let builderData = {
  editWidget: "",
  widgetName: "",
  mainCtnr: [],
  templateSetting: {},
  previewViewport: "desktop",
};
export let APOData = {
  onboardingData: null,
  Service: [],
  Events: [],
  Classes: [],
  APO_selectedData: {
    appointmentID: null,
    staffID: null,
    date: null,
    timeslot: null,
    appointmentJSON: null,
    staffJSON: null,
    serviceActiveStep: null,
  },
  APOtype: null,
  CountriesList: [],
};
export const templateJSON = {};