import Handlebars from "handlebars";
import { templateJSON } from "./BuilderProps";
import {
  convertTimeToMinutes,
  convertUnixTimestamp,
  momentDateTimeFormat,
} from "./CommonMethod";

export const registerHelper = () => {
  // Register Handlebars partials
  const partials = [
    {
      name: "commonHtmlof_servicegridlist",
      id: "commonHtmlof_servicegridlist",
    },
    { name: "SignupFormField", id: "template_SignupFormField" },
    { name: "orderdetail", id: "template_orderdetail" },
    {
      name: "PaymentPlaceholderLoading",
      id: "template_PaymentPlaceholderLoading",
    },
    { name: "servicegrid", id: "template_servicegrid" },
    { name: "filterApo", id: "template_filterApo" },
    { name: "ApoBackStep", id: "template_ApoBackStep" },
  ];

  partials.forEach(partial => registerPartial(partial.name, partial.id));

   // Helper to apply styles from a style object
  Handlebars.registerHelper("applyStyles", function (name, stylesKey, color) {
    var styles = templateJSON.StyleObj[name][stylesKey]; // Retrieve styles from StyleObj
    return new Handlebars.SafeString(
      `style="${StylejsonFN(styles)}${color.data ? "" : ` color:${color}`}"`
    );
  });

   // Helper to apply event color
  Handlebars.registerHelper("eventColor", function (color, options) {
    return new Handlebars.SafeString(`style="color: ${color}"`);
  });

  // Equality check helpers
  Handlebars.registerHelper("if_eq", function (a, b, opts) {
    return a === b ? opts.fn(this) : opts.inverse(this);
  });
  Handlebars.registerHelper("if_noteq", function (a, b, opts) {
    return a !== b ? opts.fn(this) : opts.inverse(this);
  });

  // Date and time formatting helpers
  Handlebars.registerHelper("formatDateTime", (dateString, format) => momentDateTimeFormat(dateString, format));
  Handlebars.registerHelper("formatTimestamp", (timestamp, format) => (!!timestamp ? convertUnixTimestamp(timestamp, format) : ""));

  // Time conversion helper
  Handlebars.registerHelper("convertTimeToMinutes", function (timeString) {
    const totalMinutes = convertTimeToMinutes(timeString);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return hours > 0 ? `${hours}h ${minutes}m` : `${totalMinutes}m`;
  });

   // String transformation helpers
  Handlebars.registerHelper("toLowerCase", (str) => str.toLowerCase());

  // first&last letter of string
  Handlebars.registerHelper("returnOnlyZeroindex", function (name, optios) {
    const words = name.split(" ");
    if (words.length === 1) {
      return `${name[0].toUpperCase()}${name[name.length - 1].toUpperCase()}`;
    }
    return `${words[0][0].toUpperCase()}${words[1][0].toUpperCase()}`
  });

  // Array length checker
  Handlebars.registerHelper("ifArrayLength", (array, opts) => (array && array.length > 0 ? opts.fn(this) : opts.inverse(this)));

  // Range helper to generate an array of numbers
  Handlebars.registerHelper("range", function (start, end) {
    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  });
};

// Helper function to register a partial safely
const registerPartial = async (partialName, elementId) => {
  const element = document.getElementById(elementId);
  if (element) {
    Handlebars.registerPartial(partialName, element.innerHTML);
  } else {
    console.warn(
      `Element with id "${elementId}" not found, partial "${partialName}" not registered.`
    );
  }
};

const StylejsonFN = (json) => {
  return Object.keys(json)
    .map(function (key) {
      return (
        key.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase() +
        ": " +
        json[key] +
        ";"
      );
    })
    .join("");
};
