import { builderData, APOData, templateJSON } from "./BuilderProps";
import { displayProperty } from "./DisplayProperty";
import { bgImageField, AddAccordion } from "./PropHTML";
import { SignupformFields } from "./Signupform";

const AlignmentType = (type = "text-align") => {
  const align = document.querySelectorAll(".proAlignment");
  align.forEach((item) => (item.dataset.type = type));
};

export const TemplateSettingProp = () => {
  const Prop = {
    whereInsert: ".insertTemplateProp",
    label: {
      text: "Background image",
      ischeckbox: true,
    },
    urlType: "TemplateBG",
    applyTO: false,
  };
  bgImageField(Prop);
};
// open Row property
export const openRow_Property = () => {
  const Prop = {
    whereInsert: ".insertRowProp",
    label: {
      text: "Row Background image",
      ischeckbox: true,
    },
    urlType: "RowBG",
    applyTO: true,
  };
  bgImageField(Prop);
};

// open Module property
export const openModule_Property = () => {
  const WName = builderData.widgetName;

  AlignmentType(WName !== "Text" ? "flex" : ""); //update align type

  if (WName === "Button") {
    const showProp = ["propWidth", "propAlignment"];
    displayProperty(showProp);
    ButtonStyle(WName);
  }

  if (WName === "Image") {
    const showProp = ["propWidth", "propAlignment", "propImageUpload"];
    displayProperty(showProp);
    ImageStyle();
  }

  if (WName === "TextEditor" || WName === "Description") {
    const showProp = ["propWidth", "propAlignment"];
    displayProperty(showProp);
    //const  textED = builderData.editWidget.querySelector('.textED-content');
    //window.ckinlinEditor(textED);
  }

  if (WName === "BusinessHours" || WName === "BusinessAddress") {
    const showProp = [];
    displayProperty(showProp);
    ContentStyle(WName);
  }
  if (WName === "Footer") {
    const showProp = [];
    displayProperty(showProp);
  }

  if (WName === "Menu") {
    const showProp = ["propAlignment"];
    displayProperty(showProp);
    MenuStyle();
  }

  if (WName === "Service" || WName === "SelectedService" || WName === "stafflist" || WName === "OrderDetail") {
    let showProp = ["SwitchStep"];

    const $serviceStep = $('.serviceStep');
    if (!$serviceStep.children().length) {
      const {steps} = templateJSON.StyleObj[WName];
      const options = steps.map((value, i) =>
            `<option value="${i + 1}" data-template="${value}" ${
              value === APOData.APO_selectedData.serviceActiveStep ? "selected" : ""
            }>Step ${i + 1}</option>`
        )
        .join("");

        $serviceStep.html(options);
        $serviceStep.customSelect(); // reset custom select
    }
    if(WName === "Service" && $serviceStep.val() === '1') {
      showProp.push("ServiceFilter");
    }

    displayProperty(showProp);
    ServiceStyle(WName);
  }

  if (WName === "AppointmentSlot" || WName === "Calendar") {
    const showProp = ["SwitchStep"];
    displayProperty(showProp);
    WName === "Calendar" ? CalendarStyle(WName) : AppointmentSlotStyle(WName);
  }

  if (WName === "Booking") {
    const showProp = ["propWidth", "propAlignment"];
    displayProperty(showProp);
    BookingStyle(WName);
  }

  if (WName === "BookingSearch") {
    const showProp = ["propWidth", "propAlignment"];
    displayProperty(showProp);
    // BookingStyle(WName);
  }

  if (WName === "Signup") {
    const showProp = ["SwitchStep", "propWidth", "propAlignment"];
    displayProperty(showProp);
    SignupStyle(WName);
  }

  if (WName === "TabWidget") {
    const showProp = ["propWidth", "propAlignment"];
    displayProperty(showProp);
    TabwidgetStyle(WName);
  }

  if (WName === "ReviewList") {
    const showProp = [];
    displayProperty(showProp);
    ReviewListStyle(WName);
  }

};

const ButtonStyle = async (WName) => {
  const buttonstyle = builderData.editWidget.querySelector(".buttonstyle");
  const style = await GetTextstylingFN(buttonstyle, "buttonstyle");
  const BGcolor = style.Stylejson["backgroundColor"];

  const widgetOption = [
    {
      title: `${WName} option`,
      whereInsert: ".insertAccordion-MProp",
      textInput: {
          type: "text",
          label: "Button Text",
          applyTO: "btnText",
          value: buttonstyle.innerText,
      },
      backgroundColor: [
        {
          type: "backgroundColor",
          label: "Background Color",
          applyTO: "buttonstyle",
          BGColorProp: BGcolor,
        },
      ],
      border: {
        label: "Border",
        applyTO: "borderStyle",
      },
      borderRadius: {
        label: "Border radius",
        applyTO: "borderStyle",
        border: "rounded-e-none border-r-0",
        infobox: true,
        type: "borderRadius",
        width: "80px",
      },
    },
    {
      title: "Typography",
      whereInsert: ".insertAccordion-MProp",
      Typography: {
        ActiveTextcolor: false,
        isLineH: true,
      },
    },
  ];
  AddAccordion(widgetOption, style);

  //   widgetOption.forEach(async(option) => {
  //     switch(option.title) {
  //         case "Typography":
  //             TypographyStyle(option, Stylejson);
  //             break;
  //         default:
  //             AddAccordion(option, Stylejson);
  //     }
  //   });
};

const ImageStyle = () => {

  const Prop = {
    whereInsert: ".insertModuleProp",
    label: {
      text: "Select image",
      ischeckbox: false,
    },
    urlType: "widgetSRC",
    applyTO: false,
  };
  bgImageField(Prop);
};

const ContentStyle = async (WName) => {
  const Stylejson = {
    Stylejson: templateJSON.StyleObj[WName].Textstyling,
    applyTO: "Textstyling",
  };

  const widgetOption = [
    {
      title: "Typography",
      whereInsert: ".insertAccordion-MProp",
      Typography: {
        ActiveTextcolor: false,
        isLineH: true,
      },
    },
  ];
  AddAccordion(widgetOption, Stylejson);
};


const ReviewListStyle = async (WName) => {
  const { Textstyling, Titlestyling, Substyling } = templateJSON.StyleObj[WName];


  const widgetOption = [
    {
      title: "Typography",
      whereInsert: ".insertAccordion-MProp",
      Typography: {
        ActiveTextcolor: false,
        isLineH: true,
      },
      tabs: [
        {
          text: "Content",
          icon: "fa-text-width",
          applyTO: "Textstyling",
          Stylejson: Textstyling,
        },
        ,
        {
          text: "Sub heading",
          icon: "fa-heading",
          applyTO: "Substyling",
          Stylejson: Substyling,
        },
        {
          text: "Heading",
          icon: "fa-heading",
          applyTO: "Titlestyling",
          Stylejson: Titlestyling,
        },
      ],
    },
  ];
  AddAccordion(widgetOption, Textstyling);
};

const MenuStyle = async () => {
  let Tstyling = [];
  for (const x of builderData.editWidget.querySelectorAll(".Textstyling")) {
    if (!x.classList.contains("active")) {
      Tstyling.push(x);
      break;
    }
  }
  let Stylejson = await GetTextstylingFN(Tstyling[0], "Textstyling");
  Stylejson.activecolor =
    builderData.editWidget.querySelector(".active").style.color; // active color

  const widgetOption = [
    {
      title: "Typography",
      whereInsert: ".insertAccordion-MProp",
      Typography: {
        ActiveTextcolor: true,
        isLineH: false,
      },
    },
  ];
  AddAccordion(widgetOption, Stylejson);
};

export const ServiceStyle = async (WName) => {
  // const Tstyling = builderData.editWidget.querySelectorAll(".Textstyling")[0];
  // const Hstyling = builderData.editWidget.querySelectorAll(".Titlestyling")[0];
  // const Stylejson = await GetTextstylingFN(Tstyling, "Textstyling");

  const { Textstyling, Titlestyling } = templateJSON.StyleObj[WName];

  const widgetOption = [
    {
      title: "Typography",
      whereInsert: ".insertAccordion-MProp",
      Typography: {
        ActiveTextcolor: false,
        isLineH: true,
      },
      tabs: [
        {
          text: "Content",
          icon: "fa-text-width",
          applyTO: "Textstyling",
          Stylejson: Textstyling,
        },
        {
          text: "Heading",
          icon: "fa-heading",
          applyTO: "Titlestyling",
          Stylejson: Titlestyling,
        },
      ],
    },
  ];
  AddAccordion(widgetOption, Textstyling);
};

const CalendarStyle = async (WName) => {
  //const Tstyling = builderData.editWidget.querySelector(".active");
  //var BGcolor = window.getComputedStyle(Tstyling, ':after').getPropertyValue('background-color');
  //console.log(BGcolor)
  const { backgroundColor } = templateJSON.StyleObj[WName].active;

  const widgetOption = [
    {
      title: `Decorations`,
      whereInsert: ".insertAccordion-MProp",
      backgroundColor: [
        {
          type: "backgroundColor",
          label: "Selected date color",
          applyTO: "active",
          BGColorProp: backgroundColor,
        },
      ],
    },
  ];
  AddAccordion(widgetOption, false);
};

const AppointmentSlotStyle = async (WName) => {
  const BreakH_ele = builderData.editWidget.querySelectorAll(".breakHour")[0];
  const { Timeslots, breakHour } = templateJSON.StyleObj[WName];

  const widgetOption = [
    {
      title: `Decorations`,
      whereInsert: ".insertAccordion-MProp",
      backgroundColor: [
        {
          type: "backgroundColor",
          label: "Background Color",
          applyTO: "Timeslots",
          BGColorProp: Timeslots.backgroundColor,
        },
      ],
    },
  ];
  if (!!BreakH_ele) {
    widgetOption[0].backgroundColor.push({
      type: "backgroundColor",
      label: "Break hours color",
      applyTO: "breakHour",
      BGColorProp: breakHour.backgroundColor,
    });
  }

  AddAccordion(widgetOption, false);
};

const BookingStyle = async (WName) => {

  const { servicetitle, Textstyling, borderStyle } = templateJSON.StyleObj[WName];

  const widgetOption = [
    {
      title: `${WName} option`,
      whereInsert: ".insertAccordion-MProp",
      border: {
        label: "Border",
        applyTO: "borderStyle",
      },
    },
    {
      title: "Typography",
      whereInsert: ".insertAccordion-MProp",
      Typography: {
        ActiveTextcolor: false,
        isLineH: true,
      },
      tabs: [
        {
          text: "Content",
          icon: "fa-text-width",
          applyTO: "Textstyling",
          Stylejson: Textstyling, 
        },
        {
          text: "Heading",
          icon: "fa-heading",
          applyTO: "servicetitle",
          Stylejson: servicetitle,
        },
      ],
    },
  ];

  AddAccordion(widgetOption, {Stylejson: borderStyle});
};

const SignupStyle = async (WName) => {
  const { widgetstyle, Textstyling, inputstyle, form } = templateJSON.StyleObj.Signup;
  const margeStyle = {
    Stylejson: { ...widgetstyle, ...Textstyling, ...inputstyle },
    applyTO: "Textstyling"
  };

  const widgetOption = [
    {
      title: `${WName} option`,
      whereInsert: ".insertAccordion-MProp",
      backgroundColor: [
        {
          type: "backgroundColor",
          label: "Background Color",
          applyTO: "widgetstyle",
          BGColorProp: margeStyle.backgroundColor,
        },
      ],
      border: {
        label: "Border",
        applyTO: "inputstyle",
      },
      borderRadius: {
        label: "Border radius",
        applyTO: "inputstyle",
        border: "rounded-e-none border-r-0",
        infobox: true,
        type: "borderRadius",
        width: "80px",
      },
    }
  ];
  
  if (form.fields[0].label.show) {
    widgetOption.splice(1, 0, {
      title: "Typography",
      whereInsert: ".insertAccordion-MProp",
      Typography: {
        ActiveTextcolor: false,
        isLineH: true,
      },
    });
  }

  await AddAccordion(widgetOption, margeStyle);
  const fieldHtml = await SignupformFields();
};

const TabwidgetStyle = async(WName) => {
  const { widget__tabtitle, active } = templateJSON.StyleObj[WName];
  const margeStyle = {
    Stylejson: { ...widget__tabtitle, activecolor: active.color },
    applyTO: "widget__tabtitle"
  };

  const widgetOption = [
    {
      title: `${WName} option`,
      whereInsert: ".insertAccordion-MProp",
      border: {
        label: "Border",
        applyTO: "borderStyle",
      }
    },
    {
      title: "Typography",
      whereInsert: ".insertAccordion-MProp",
      Typography: {
        ActiveTextcolor: true,
        isLineH: false,
      },
    },
  ];
  await AddAccordion(widgetOption, margeStyle);
};

const GetTextstylingFN = async (element, TO) => {
  const Tstyling = element.style;
  let style = {
    Stylejson: {
      fontWeight: Tstyling.fontWeight,
      fontSize: Tstyling.fontSize.replace(/[px%]/g, ""),
      fontFamily: Tstyling.fontFamily.replace(/["]/g, ""),
      letterSpacing: Tstyling.letterSpacing.replace(/[px%]/g, ""),
      borderWidth: Tstyling.borderWidth.replace(/[px%]/g, ""),
      borderRadius: Tstyling.borderRadius.replace(/[px%]/g, ""),
      borderStyle: Tstyling.borderStyle,
      borderColor: Tstyling.borderColor,
      color: Tstyling.color,
      backgroundColor: Tstyling.backgroundColor,
      lineHeight: Tstyling.lineHeight,
    },
    applyTO: TO,
  };
  return style;
};
